// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ModalsContext$GlazedWebsite from "./ModalsContext.js";
import * as StatelessModalController$GlazedWebsite from "./StatelessModalController.js";

function reducer(state, action) {
  if (action.tag) {
    var modal = action[0];
    return {
            openedModals: Belt_Array.keep(state.openedModals, (function (openedModal) {
                    return openedModal !== modal;
                  })),
            counter: state.counter + 1 | 0
          };
  } else {
    return {
            openedModals: Belt_Array.concat(state.openedModals, [action[0]]),
            counter: state.counter + 1 | 0
          };
  }
}

function ModalsController(Props) {
  var children = Props.children;
  var className = Props.className;
  var onKeyDown = Props.onKeyDown;
  var getInitialOpenedModalsOpt = Props.getInitialOpenedModals;
  var getInitialOpenedModals = getInitialOpenedModalsOpt !== undefined ? getInitialOpenedModalsOpt : (function (param) {
        return [];
      });
  var initialState_openedModals = Curry._1(getInitialOpenedModals, /* () */0);
  var initialState = {
    openedModals: initialState_openedModals,
    counter: 0
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var openModal = React.useCallback((function (modalRenderer) {
          var newModal_id = state.counter;
          var newModal = {
            id: newModal_id,
            renderer: modalRenderer
          };
          Curry._1(dispatch, /* OpenModal */Block.__(0, [newModal]));
          return newModal;
        }), [dispatch]);
  var closeModal = React.useCallback((function (modal) {
          return Curry._1(dispatch, /* CloseModal */Block.__(1, [modal]));
        }), [dispatch]);
  var contextAPI = React.useMemo((function () {
          return {
                  openedModals: state.openedModals,
                  openModal: openModal,
                  closeModal: closeModal
                };
        }), /* tuple */[
        state.openedModals,
        openModal,
        closeModal
      ]);
  var onKeyDown$1 = React.useCallback((function ($$event) {
          var eventKeyCode = $$event.keyCode;
          var openedModalsCount = state.openedModals.length;
          if (eventKeyCode === 27 && openedModalsCount > 0) {
            $$event.preventDefault();
            var match = Belt_Array.get(state.openedModals, state.openedModals.length - 1 | 0);
            if (match !== undefined) {
              return Curry._1(closeModal, match);
            } else {
              return /* () */0;
            }
          } else if (onKeyDown !== undefined) {
            return Curry._1(onKeyDown, $$event);
          } else {
            return /* () */0;
          }
        }), /* tuple */[
        state,
        closeModal
      ]);
  var tmp = {
    children: children,
    onKeyDown: onKeyDown$1,
    onModalClose: closeModal,
    openedModals: state.openedModals
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(ModalsContext$GlazedWebsite.Provider.make, ModalsContext$GlazedWebsite.Provider.makeProps(contextAPI, React.createElement(StatelessModalController$GlazedWebsite.make, tmp), /* () */0));
}

function useContextAPI(param) {
  return React.useContext(ModalsContext$GlazedWebsite.context);
}

var make = ModalsController;

var $$default = ModalsController;

export {
  reducer ,
  make ,
  $$default ,
  $$default as default,
  useContextAPI ,
  
}
/* react Not a pure module */

// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_SetString from "bs-platform/lib/es6/belt_SetString.js";

var Types = { };

function combineClassNames(param) {
  return List.fold_left((function (result, className) {
                if (result !== undefined) {
                  var result$prime = result;
                  if (className !== undefined) {
                    return Curry._1(Css.merge, /* :: */[
                                result$prime,
                                /* :: */[
                                  className,
                                  /* [] */0
                                ]
                              ]);
                  } else {
                    return result$prime;
                  }
                } else {
                  return className;
                }
              }), undefined, param);
}

var React = {
  Types: Types,
  combineClassNames: combineClassNames
};

var Types$1 = { };

function getScrollPosition(element) {
  return {
          scrollTop: element.scrollTop,
          scrollLeft: element.scrollLeft,
          scrollWidth: element.scrollWidth,
          scrollHeight: element.scrollHeight
        };
}

function getBoundingClientRect(element) {
  var domRect = element.getBoundingClientRect();
  return {
          top: domRect.top,
          right: domRect.right,
          bottom: domRect.bottom,
          left: domRect.left,
          width: domRect.width,
          height: domRect.height
        };
}

var Measurements = {
  getScrollPosition: getScrollPosition,
  getBoundingClientRect: getBoundingClientRect
};

var Dom = {
  Types: Types$1,
  Measurements: Measurements
};

function throttle(runTask) {
  var isAlreadyScheduled = {
    contents: false
  };
  return (function (param) {
      if (isAlreadyScheduled.contents) {
        return 0;
      } else {
        isAlreadyScheduled.contents = true;
        Curry._1(runTask, /* () */0);
        requestAnimationFrame((function (_timestamp) {
                isAlreadyScheduled.contents = false;
                return /* () */0;
              }));
        return /* () */0;
      }
    });
}

function throttle1(runTask) {
  var isAlreadyScheduled = {
    contents: false
  };
  return (function (argument1) {
      if (isAlreadyScheduled.contents) {
        return 0;
      } else {
        isAlreadyScheduled.contents = true;
        Curry._1(runTask, argument1);
        requestAnimationFrame((function (_timestamp) {
                isAlreadyScheduled.contents = false;
                return /* () */0;
              }));
        return /* () */0;
      }
    });
}

var Timing = {
  throttle: throttle,
  throttle1: throttle1
};

var iDevices = Belt_SetString.fromArray([
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ]);

function isIOSDevice(param) {
  if (typeof window !== "undefined") {
    return Belt_SetString.has(iDevices, window.navigator.platform);
  } else {
    return false;
  }
}

var Platform = {
  iDevices: iDevices,
  isIOSDevice: isIOSDevice
};

var email = new RegExp("^(([^<>()\\[\\]\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$");

var Regex = {
  email: email
};

function getPlatform(param) {
  if (process.browser) {
    return /* Browser */0;
  } else {
    return /* Node */1;
  }
}

var Env = {
  getPlatform: getPlatform
};

export {
  React ,
  Dom ,
  Timing ,
  Platform ,
  Regex ,
  Env ,
  
}
/* iDevices Not a pure module */

// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../Utils.js";
import * as ScrollContext$GlazedWebsite from "./ScrollContext.js";
import * as ScrollContainerUtils$GlazedWebsite from "./ScrollContainerUtils.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.overflow(/* auto */-1065951377),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flex3(1, 0, /* `rem */[
                5691738,
                0.000000001
              ]),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.unsafe("-webkitOverflowScrolling", "auto"),
              /* :: */[
                Css.unsafe("msOverflowStyle", "-ms-autohiding-scrollbar"),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flex3(1, 0, /* auto */-1065951377),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* [] */0
        ]
      ]
    ]);

var locked = Curry._1(Css.style, /* :: */[
      Css.overflow(/* hidden */-862584982),
      /* :: */[
        Css.unsafe("webkitOverflowScrolling", "auto"),
        /* [] */0
      ]
    ]);

var iOSScrollFix = Curry._1(Css.style, /* :: */[
      Css.flex3(1, 0, Css_Core.Calc.$plus(Css.pct(100), Css.px(2))),
      /* [] */0
    ]);

var Styles = {
  wrapper: wrapper,
  content: content,
  locked: locked,
  iOSScrollFix: iOSScrollFix
};

var initialState = {
  arePointerEventsEnabled: true
};

function reducer(_state, action) {
  return {
          arePointerEventsEnabled: action[0]
        };
}

function ScrollContainer(Props) {
  var lockOpt = Props.lock;
  var disablePointerEventsOnScrollOpt = Props.disablePointerEventsOnScroll;
  var scrollingTimeoutOpt = Props.scrollingTimeout;
  var innerRef = Props.innerRef;
  var children = Props.children;
  var className = Props.className;
  var contentClassName = Props.contentClassName;
  var style = Props.style;
  var contentStyle = Props.contentStyle;
  var onClick = Props.onClick;
  var onScroll = Props.onScroll;
  var lock = lockOpt !== undefined ? lockOpt : false;
  var disablePointerEventsOnScroll = disablePointerEventsOnScrollOpt !== undefined ? disablePointerEventsOnScrollOpt : false;
  var scrollingTimeout = scrollingTimeoutOpt !== undefined ? scrollingTimeoutOpt : 100;
  var wrapperRef = React.useRef(null);
  var didTouchEnd = React.useRef(false);
  var scrollingTimeoutId = React.useRef(undefined);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var partial_arg = Utils$GlazedWebsite.Platform.isIOSDevice;
  var isIOSDevice = React.useMemo((function () {
          return Curry._1(partial_arg, /* () */0);
        }), ([]));
  var scrollContext = React.useContext(ScrollContext$GlazedWebsite.context);
  var match$1 = ScrollContext$GlazedWebsite.useScrollerAPI(wrapperRef);
  var notifySubscribedListeners = match$1[1];
  var scrollerAPI = match$1[0];
  var scrollContextToPassDown = React.useMemo((function () {
          if (scrollContext.hasRootContainer) {
            return {
                    hasRootContainer: scrollContext.hasRootContainer,
                    closestScroller: scrollerAPI,
                    rootScroller: scrollContext.rootScroller
                  };
          } else {
            return {
                    hasRootContainer: true,
                    closestScroller: scrollerAPI,
                    rootScroller: scrollerAPI
                  };
          }
        }), /* tuple */[
        scrollerAPI,
        scrollContext.hasRootContainer
      ]);
  var needIOSHackery = isIOSDevice && !scrollContext.hasRootContainer;
  var handleTouchStart = React.useCallback((function (_event) {
          if (didTouchEnd.current) {
            didTouchEnd.current = false;
            return /* () */0;
          } else {
            return 0;
          }
        }));
  var handleTouchEnd = React.useCallback((function (_event) {
          didTouchEnd.current = true;
          return /* () */0;
        }));
  var scrollElementIfOnLimit = React.useCallback((function (param) {
          if (needIOSHackery) {
            var match = wrapperRef.current;
            if (match == null) {
              return /* () */0;
            } else {
              return ScrollContainerUtils$GlazedWebsite.scrollElementIfOnLimit(match);
            }
          } else {
            return 0;
          }
        }), [needIOSHackery]);
  var handleScrollEnd = React.useCallback((function (param) {
          if (disablePointerEventsOnScroll) {
            Curry._1(dispatch, /* TogglePointerEvents */[true]);
          }
          if (didTouchEnd.current) {
            return Curry._1(scrollElementIfOnLimit, /* () */0);
          } else {
            return 0;
          }
        }), /* tuple */[
        disablePointerEventsOnScroll,
        dispatch,
        scrollElementIfOnLimit
      ]);
  var handleScroll = React.useCallback(Utils$GlazedWebsite.Timing.throttle1((function (_event) {
              Curry._1(notifySubscribedListeners, /* () */0);
              if (disablePointerEventsOnScroll || needIOSHackery) {
                var match = scrollingTimeoutId.current;
                if (match !== undefined) {
                  clearTimeout(Caml_option.valFromOption(match));
                }
                scrollingTimeoutId.current = Caml_option.some(setTimeout(handleScrollEnd, scrollingTimeout));
                if (disablePointerEventsOnScroll) {
                  Curry._1(dispatch, /* TogglePointerEvents */[false]);
                }
                
              }
              if (onScroll !== undefined) {
                Curry._1(onScroll, /* () */0);
              }
              return /* () */0;
            })), /* tuple */[
        notifySubscribedListeners,
        disablePointerEventsOnScroll,
        dispatch
      ]);
  var attachScrollListener = React.useCallback((function (param) {
          var match = wrapperRef.current;
          if (match == null) {
            return /* () */0;
          } else {
            match.addEventListener("scroll", handleScroll, {
                  passive: true,
                  once: false,
                  capture: false
                });
            return /* () */0;
          }
        }));
  var detachScrollListener = React.useCallback((function (param) {
          var match = wrapperRef.current;
          if (match == null) {
            return /* () */0;
          } else {
            match.removeEventListener("scroll", handleScroll);
            return /* () */0;
          }
        }));
  var match$2 = React.useMemo((function () {
          if (needIOSHackery) {
            return /* tuple */[
                    (function ($$event) {
                        var match = wrapperRef.current;
                        if (!(match == null)) {
                          ScrollContainerUtils$GlazedWebsite.scrollElementIfOnLimit(match);
                        }
                        return Curry._1(handleTouchStart, $$event);
                      }),
                    handleTouchEnd
                  ];
          } else {
            return /* tuple */[
                    undefined,
                    undefined
                  ];
          }
        }), [needIOSHackery]);
  var callbackDomRef = function (element) {
    wrapperRef.current = element;
    if (innerRef !== undefined) {
      innerRef.current = element;
      return /* () */0;
    } else {
      return /* () */0;
    }
  };
  var tmp = { };
  var tmp$1 = match[0].arePointerEventsEnabled ? undefined : "none";
  if (tmp$1 !== undefined) {
    tmp.pointerEvents = Caml_option.valFromOption(tmp$1);
  }
  var pointerEventsStyle = tmp;
  var className$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* :: */[
            lock ? locked : undefined,
            /* [] */0
          ]
        ]
      ]);
  var contentClassName$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        content,
        /* :: */[
          contentClassName,
          /* :: */[
            needIOSHackery ? iOSScrollFix : undefined,
            /* [] */0
          ]
        ]
      ]);
  var contentStyle$1 = contentStyle !== undefined ? Object.assign(({}), Caml_option.valFromOption(contentStyle), pointerEventsStyle) : pointerEventsStyle;
  React.useEffect((function () {
          Curry._1(attachScrollListener, /* () */0);
          return detachScrollListener;
        }));
  var tmp$2 = {
    ref: callbackDomRef
  };
  if (className$1 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(className$1);
  }
  if (style !== undefined) {
    tmp$2.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp$2.onClick = Caml_option.valFromOption(onClick);
  }
  var tmp$3 = match$2[1];
  if (tmp$3 !== undefined) {
    tmp$2.onTouchEnd = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = match$2[0];
  if (tmp$4 !== undefined) {
    tmp$2.onTouchStart = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = {
    style: contentStyle$1
  };
  if (contentClassName$1 !== undefined) {
    tmp$5.className = Caml_option.valFromOption(contentClassName$1);
  }
  return React.createElement(ScrollContext$GlazedWebsite.Provider.make, ScrollContext$GlazedWebsite.Provider.makeProps(scrollContextToPassDown, React.createElement("div", tmp$2, React.createElement("div", tmp$5, children)), /* () */0));
}

var defaultScrollingTimeout = 100;

var make = ScrollContainer;

var $$default = ScrollContainer;

export {
  Styles ,
  defaultScrollingTimeout ,
  initialState ,
  reducer ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */

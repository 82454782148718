module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Glazed • Elite Developers","short_name":"Glazed","start_url":"/","background_color":"#2560F2","theme_color":"#2560F2","display":"minimal-ui","icon":"static/images/logo-glazed-small.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ed9a92f3e918b159053b4796d4900564"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/components/blogLayout.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-JEE9TEWH73"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

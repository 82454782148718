// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as GatsbyReactRouterScroll from "gatsby-react-router-scroll";
import * as ScrollContainer$GlazedWebsite from "./shared/scroll-container/ScrollContainer.js";
import * as ModalsController$GlazedWebsite from "./shared/modals/ModalsController.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.height(Css.pct(100)),
      /* [] */0
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.height(Css.pct(100)),
      /* [] */0
    ]);

var Styles = {
  wrapper: wrapper,
  content: content
};

function shouldUpdateScroll(_previousRouterProps, routerProps) {
  if (routerProps == null) {
    return true;
  } else {
    var $$location = routerProps.location;
    if ($$location.action === "PUSH") {
      var match = $$location.state;
      if (match == null) {
        return true;
      } else {
        var state = match.state;
        if (state !== undefined) {
          return !state.preventDefaultScrollBehavior;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }
}

function PageElementWrapper(Props) {
  var children = Props.children;
  return React.createElement(ModalsController$GlazedWebsite.make, {
              children: React.createElement(GatsbyReactRouterScroll.ScrollContainer, {
                    scrollKey: "scrollContainer",
                    shouldUpdateScroll: shouldUpdateScroll,
                    children: React.createElement(ScrollContainer$GlazedWebsite.make, {
                          children: children,
                          className: wrapper,
                          contentClassName: content
                        })
                  })
            });
}

var make = PageElementWrapper;

var $$default = PageElementWrapper;

export {
  Styles ,
  shouldUpdateScroll ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */

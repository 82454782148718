// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils$GlazedWebsite from "../Utils.js";

var initialScrollPosition = {
  scrollTop: 0,
  scrollLeft: 0,
  scrollWidth: 0,
  scrollHeight: 0
};

var initialBoundingRect = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0
};

function defaultScrollerAPI_registerScrollListener(_component, _handler) {
  return /* () */0;
}

function defaultScrollerAPI_unregisterScrollListener(_component) {
  return /* () */0;
}

function defaultScrollerAPI_getScrollPosition(param) {
  return initialScrollPosition;
}

function defaultScrollerAPI_getBoundingRect(param) {
  return initialBoundingRect;
}

function defaultScrollerAPI_scrollTo(_top, _left) {
  return /* () */0;
}

var defaultScrollerAPI = {
  registerScrollListener: defaultScrollerAPI_registerScrollListener,
  unregisterScrollListener: defaultScrollerAPI_unregisterScrollListener,
  getScrollPosition: defaultScrollerAPI_getScrollPosition,
  getBoundingRect: defaultScrollerAPI_getBoundingRect,
  scrollTo: defaultScrollerAPI_scrollTo
};

var defaultContextValue = {
  hasRootContainer: false,
  closestScroller: defaultScrollerAPI,
  rootScroller: defaultScrollerAPI
};

var context = React.createContext(defaultContextValue);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

function useScrollerAPI(wrapperRef) {
  var scrollListeners = React.useRef(/* [] */0);
  var registerScrollListener = React.useCallback((function (component) {
          return (function (handler) {
              scrollListeners.current = /* :: */[
                /* tuple */[
                  component,
                  handler
                ],
                scrollListeners.current
              ];
              return /* () */0;
            });
        }), [scrollListeners]);
  var unregisterScrollListener = React.useCallback((function (component) {
          scrollListeners.current = Belt_List.keep(scrollListeners.current, (function (param) {
                  return param[0] !== component;
                }));
          return /* () */0;
        }), [scrollListeners]);
  var notifySubscribedListeners = React.useCallback((function (param) {
          List.iter((function (param) {
                  return Curry._1(param[1], /* () */0);
                }), scrollListeners.current);
          return /* () */0;
        }), [scrollListeners]);
  var getScrollPosition = React.useCallback((function (param) {
          var match = wrapperRef.current;
          if (match == null) {
            return initialScrollPosition;
          } else {
            return Curry._1(Utils$GlazedWebsite.Dom.Measurements.getScrollPosition, match);
          }
        }), [wrapperRef]);
  var getBoundingRect = React.useCallback((function (param) {
          var match = wrapperRef.current;
          if (match == null) {
            return initialBoundingRect;
          } else {
            return Curry._1(Utils$GlazedWebsite.Dom.Measurements.getBoundingClientRect, match);
          }
        }), [wrapperRef]);
  var scrollTo = React.useCallback((function (top) {
          return (function (left) {
              var match = wrapperRef.current;
              if (match == null) {
                return /* () */0;
              } else {
                if (top !== undefined) {
                  match.scrollTop = top;
                }
                if (left !== undefined) {
                  match.scrollLeft = left;
                  return /* () */0;
                } else {
                  return /* () */0;
                }
              }
            });
        }));
  var scrollerAPI = React.useMemo((function () {
          return {
                  registerScrollListener: registerScrollListener,
                  unregisterScrollListener: unregisterScrollListener,
                  getScrollPosition: getScrollPosition,
                  getBoundingRect: getBoundingRect,
                  scrollTo: scrollTo
                };
        }), /* tuple */[
        registerScrollListener,
        unregisterScrollListener,
        getScrollPosition,
        getBoundingRect,
        scrollTo
      ]);
  return /* tuple */[
          scrollerAPI,
          notifySubscribedListeners
        ];
}

export {
  initialScrollPosition ,
  initialBoundingRect ,
  defaultScrollerAPI ,
  defaultContextValue ,
  context ,
  Provider ,
  useScrollerAPI ,
  
}
/* context Not a pure module */

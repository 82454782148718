// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../Utils.js";
import * as ReactTransitionGroup from "react-transition-group";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flex3(1, 1, /* `rem */[
                5691738,
                0.00000001
              ]),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* [] */0
          ]
        ]
      ]
    ]);

var overlayContainer = Curry._1(Css.style, /* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var Styles = {
  wrapper: wrapper,
  overlayContainer: overlayContainer
};

function StatelessModalController(Props) {
  var children = Props.children;
  var className = Props.className;
  var onKeyDown = Props.onKeyDown;
  var onModalClose = Props.onModalClose;
  var openedModals = Props.openedModals;
  var tmp = {
    role: "presentation"
  };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  if (onKeyDown !== undefined) {
    tmp.onKeyDown = Caml_option.valFromOption(onKeyDown);
  }
  return React.createElement("div", tmp, children, React.createElement("div", {
                  className: overlayContainer,
                  tabIndex: 0
                }, React.createElement(ReactTransitionGroup.TransitionGroup, {
                      children: Belt_Array.map(openedModals, (function (modal) {
                              return Curry._2(modal.renderer, modal.id, (function (param) {
                                            return Curry._1(onModalClose, modal);
                                          }));
                            }))
                    })));
}

var make = StatelessModalController;

export {
  Styles ,
  make ,
  
}
/* wrapper Not a pure module */

// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";

var defaultContextValue_openedModals = [];

function defaultContextValue_openModal(_modal) {
  return {
          id: -1,
          renderer: (function (param, param$1) {
              return null;
            })
        };
}

function defaultContextValue_closeModal(_modal) {
  return /* () */0;
}

var defaultContextValue = {
  openedModals: defaultContextValue_openedModals,
  openModal: defaultContextValue_openModal,
  closeModal: defaultContextValue_closeModal
};

var context = React.createContext(defaultContextValue);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

export {
  defaultContextValue ,
  context ,
  Provider ,
  
}
/* context Not a pure module */

// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Webapi__Dom__Element from "bs-webapi/lib/es6/src/Webapi/Dom/Webapi__Dom__Element.js";

function scrollElementIfOnLimit(element) {
  var top = element.scrollTop;
  if (top <= 0) {
    element.scrollTop = 1;
    return /* () */0;
  } else {
    var match = Webapi__Dom__Element.asHtmlElement(element);
    if (match !== undefined && (top | 0) >= (element.scrollHeight - Caml_option.valFromOption(match).offsetHeight | 0)) {
      element.scrollTop = top - 1;
      return /* () */0;
    } else {
      return /* () */0;
    }
  }
}

export {
  scrollElementIfOnLimit ,
  
}
/* Webapi__Dom__Element Not a pure module */

// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var Colors = {
  black: "000000",
  white: "FFFFFF",
  almostWhite: "FEFFFE",
  almostAlmostWhite: "FAFBFF",
  orange: "FFB665",
  lightGrey: "D9E3FF",
  grey: "9FA8B3",
  darkGrey: "4F5C6B",
  darkGreyDarker: "26313D",
  blueDarker: "0E4AA0",
  whiteTurquoise: "F2F7FB",
  glazedBlue: "024DD1",
  glazedBlueLighter: "537EED",
  glazedBlueDark: "235DEA",
  glazedBlueDarkish: "2D6AF7",
  glazedBlueDarker: "2158df",
  glazedBlueDarkerish: "235BE5",
  glazedBlueDarkestish: "1a45b6",
  glazedBlueDarkest: "024DD1",
  glazedBlueMidnight: "010C31",
  glazedBabyBlue: "53D3FF",
  glazedBabyBlueLight: "7FDAFF",
  glazedBabyBlueDarker: "69C0FE",
  glazedBabyBlueText: "2560F2",
  glazedGreyText: "0F1936"
};

var Fonts = {
  heading: "ZonaProBold",
  text: "'Muli', sans-serif"
};

var Breakpoints = {
  smallPhone: "(min-width: 0px)",
  smallPhoneValue: 0,
  phone: "(min-width: 375px)",
  phoneValue: 375,
  phoneLandscape: "(min-width: 576px)",
  phoneLandscapeValue: 576,
  tabletPortrait: "(min-width: 768px)",
  tabletPortraitValue: 768,
  tabletLandscape: "(min-width: 992px)",
  tabletLandscapeValue: 992,
  desktop: "(min-width: 1200px)",
  desktopValue: 1200
};

export {
  Colors ,
  Fonts ,
  Breakpoints ,
  
}
/* No side effect */

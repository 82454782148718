// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Emotion from "emotion";
import * as Core from "@emotion/core";
import * as MediaContext from "src/components/mediaContext";

var CacheProvider = { };

var MediaContextProvider = { };

function RootElementWrapper(Props) {
  var children = Props.children;
  return React.createElement(Core.CacheProvider, {
              children: React.createElement(MediaContext.MediaContextProvider, {
                    children: children
                  }),
              value: Emotion.cache
            });
}

var make = RootElementWrapper;

var $$default = RootElementWrapper;

export {
  CacheProvider ,
  MediaContextProvider ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
